<header class="header-element sticky-top bg-white">
    <div class="container">
        <nav class="header-block pro-header">
            <a routerLink="/pages/home" class="brand ">
                <img src="assets/images/updated-logo.png" class="mr-5 logo-contants">
      
            </a>
            <div class="header-address mx-auto top-header">
                <div class="custom-input-group w-50 mx-2">
                    <input type="text" placeholder="Search" #pendingSearch matInput placeholder="Search" name="search" [(ngModel)]="headerSearch"
                    class="form-control" type="text" autocomplete="off"
                        class="custom-control pe-5 bg-transparent b-all rounded-pill pl-3">
                    <i class="mdi mdi-magnify font-30 right text-default cusor-point "></i>

                    <!-- <div class="search_items">
                        <ul *ngIf="searchSuggestionList && searchSuggestionList.length>0">
                            <li *ngFor="let list of searchSuggestionList">
                                <p (click)="onSelect(list)">{{currentLang =='en'? list['name'] : list['nameInArr']}}
                                    <span *ngIf="list.type=='category'">{{'CATEGORY'| translate }}</span>
                                    <span *ngIf="list.type=='subCategory'">{{'SUB CATEGORY'| translate }}</span>
                                    <span *ngIf="list.type=='product'">{{'PRODUCT'| translate }}</span>
                                </p>
                            </li>
                        </ul>
                        <ul
                            *ngIf="searchSuggestionList && searchSuggestionList.length==0 && !selectedKey && search.length>1">
                            <li>
                                <p>{{'NO SEARCH RESULT'| translate }}
                                </p>
                            </li>
                        </ul>
                    </div> -->
               
                </div>
                <div class="custom-input-group location">
                    <form [formGroup]="location">
                        <i class="mdi mdi-map-marker left font-24 text-secondary"></i>
                        <input class="custom-control ps-5 b-0 bg-transparent" matInput type="text"
                            ngx-google-places-autocomplete #placesRef="ngx-places"
                            (keyup)="onKeyUpp($event.target.value)" (change)="pickupChange($event.target.value)"
                            (click)="handlePickUpChnage($event)" (onAddressChange)="handlePickUpChnage($event)"
                            placeholder="Location" formControlName="address">
                            
                        <!-- <i class="mdi mdi-chevron-down font-30 right text-default cusor-point "></i> -->

                    </form>
                </div>
                <div class="custom-input-group align-items-sm-center px-2 cusor-point">
                        <figure class="hover m-0" title="wishlish" routerLink="/pages/wishlish">
                        <img src="assets/images/whislist.svg" alt="chat">
                    </figure>
                </div>
                <div class="custom-input-group align-items-sm-center px-2 cusor-point">
                    <figure class="hover m-0" title="Notification" routerLink="/pages/notification">
                        <img src="assets/images/notification-bell-svgrepo-com.svg" style="width: 31px; height: 33px;" alt="chat">
                    </figure>
                </div>
                <div class="custom-input-group align-items-sm-center px-2 cusor-point">
                    <figure class="hover m-0" title="Cart" routerLink="/pages/shopingitem">
                        <img src="assets/images/cart-icon.svg" alt="chat">
                    </figure>
                    <span class="cartCount w-100 rounded-circle p-t-10 p-b-10" *ngIf="cartCount>0" routerLink="/pages/shopingitem">
                        <span class="numberCss"> 
                         <strong>   {{cartCount || '0'}}</strong>
                        </span>
                    </span>
                </div>
                <div class="custom-input-group align-items-sm-center px-2">
                    <div class="align-items-sm-center d-flex usr-profile">
                        <!-- <figure class="m-0">
                            <img src="assets/images/profile-icon.jpg" alt="chat">
                        </figure> -->
                        <ng-container
                            *ngIf="userDetails || (userDetails && userDetails['firstName']) || (userDetails && userDetails['isEmailVerified']) || (userDetails && userDetails['isPhoneVerified'])">

                            <div class="btn-group" dropdown>
                                <button id="button-basic" dropdownToggle type="button"
                                    class=" border-0 bg-transparent dropdown-toggle p-0 d-flex align-items-center"
                                    aria-controls="dropdown-basic">
                                    <figure  class="image">
                                        <img [src]="data?.user?.profilePic ? data?.user?.profilePic: 'assets/images/defaultuser.jpg'" alt="chat" style="
                                         width: 50px;
                                                height: 50px;
                                                border-radius: 100%;
                                                object-fit: cover;
                                    ">
                                    </figure>
                                </button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                                    aria-labelledby="button-basic">
                                    <li role="menuitem" class="">
                                        <a class="dropdown-item d-flex align-items-center" routerLink="/pages/profile">
                                            <div class="lft">
                                                <img style="
                                                width: 50px;
                                                height: 50px;
                                                border-radius: 100%;
                                                object-fit: cover;
                                            " [src]="data?.user?.profilePic ? data?.user?.profilePic: 'assets/images/defaultuser.jpg'" class="mr-2 " alt="" >
                                            </div>
                                            <div class="ryt">
                                                <span class="text-secondary d-block">Welcome</span>{{data?.user?.firstName+' '+data?.user?.lastName}}
                                            </div>
                                        </a>
                                    </li>
                                    <div class="back-drop">
                                        <li role="menuitem" class=""><a class="dropdown-item"
                                                [routerLink]="['/pages/myorder']"><img src="assets/images/order-icn.svg"
                                                    class="mr-3 img-width" alt=""> My Orders</a></li>
                                        <li role="menuitem" routerLink="/pages/addressmanage" class=" cr-point"><a
                                                class="dropdown-item"><img src="assets/images/mng-add.svg"
                                                    class="mr-3 img-width" alt=""> Manage Addresses</a></li>
                                        <li role="menuitem" class=""><a class="dropdown-item"
                                                routerLink="/pages/ManagePayment"><img src="assets/images/paymt-icn.svg"
                                                    class="mr-3 img-width" alt=""> Manage Payment Methods</a></li>
                                        <li role="menuitem" class=""><a class="dropdown-item" routerLink="/pages/Subscriptions"><img
                                                    src="assets/images/sub-icn.svg" class="mr-3 img-width" alt="">
                                                Subscriptions</a></li>
                                        <!-- <li role="menuitem" class=""><a class="dropdown-item" routerLink=""><img src="assets/images/noti-icn.svg"class="mr-2" alt=""> Manage Notifications</a></li> -->
                                        <li role="menuitem" class=""><a class="dropdown-item"
                                                routerLink="/pages/changepassword"><img src="assets/images/cng-icn.svg"
                                                    class="mr-3 img-width" alt=""> Change Password</a></li>
                                        <!-- <li role="menuitem" class=""><a class="dropdown-item" routerLink="/wishlish"><img src="assets/images/whislist.svg"class="mr-3 img-width" alt=""> Whislist</a></li> -->
                                    </div>
                                    <!-- <li role="menuitem" class=""><a class="dropdown-item"
                                            routerLink="/changepassword"><img src="assets/images/term.svg"
                                                class="mr-3 img-width" alt=""> Terms &
                                            Conditions</a></li>
                                    <li role="menuitem" class=""><a class="dropdown-item"
                                            routerLink="/changepassword"><img src="assets/images/about.svg"
                                                class="mr-3 img-width" alt=""> About Us</a>
                                    </li>
                                    <li role="menuitem" class=""><a class="dropdown-item"
                                            routerLink="/changepassword"><img src="assets/images/contact.svg"
                                                class="mr-3 img-width" alt=""> Contact
                                            Us</a></li>
                                    <li role="menuitem" class=""><a class="dropdown-item"
                                            routerLink="/changepassword"><img src="assets/images/faq.svg"
                                                class="mr-3 img-width" alt=""> FAQs</a></li> -->
                                    <li class="pt-2 px-2 r"> <button
                                            class="btn  bg-danger px-3 text-white  rounded-pill w-100"
                                            (click)="logOut()">Logout</button></li>

                                </ul>
                            </div>

                        </ng-container>

                        <!-- <a class="nav-link " href="#" id="notification-drop" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                              <img src="assets/images/down-icon.jpg" alt="down">
                            </a>-->


                    </div>

                </div>
            </div>
            <ng-container *ngIf="!userDetails">
                <button class=" btn-primary px-4 ms-auto rounded-pill text-white bg-primary"
                    (click)="openModal()">Login/Signup </button>

            </ng-container>

        </nav>
    </div>
    <div class="sub_header  p-10 mt-3">
        <div class="container">
            <ul class="align-items-center d-flex justify-content-between m-0">
                <li *ngIf="cat.length > 0"><a class="text-black font-16 font-normal"  routerLink="/pages/categoryheader/" (click)="selectedType = 'All'" [ngClass]="selectedType == 'All' ? 'active':''">All</a></li>
                <!-- <li><a href="" class="text-black font-16 font-normal" routerLink="/pages/recommendedproduct/top">Best Sellers</a></li>
                <li><a href="" class="text-black font-16 font-normal">Fashion</a></li>
                <li><a href="" class="text-black font-16 font-normal">Electronics</a></li>
                <li><a href="" class="text-black font-16 font-normal">Household</a></li>
                <li><a href="" class="text-black font-16 font-normal">Furniture</a></li>
                <li><a href="" class="text-black font-16 font-normal">Laptops</a></li>
                <li><a href="" class="text-black font-16 font-normal">Mobiles</a></li>
                <li><a href="" class="text-black font-16 font-normal">Watches</a></li>
                <li><a href="" class="text-danger font-18 font-medium">SHOP NOW</a></li> -->
                <div *ngFor= "let data of cat;let i = index" (click)="selectedType = data['name']" [ngClass]="selectedType == data['name'] ? 'active':''">
                    <li><a class="text-black font-16 font-normal" routerLink="/pages/categoryheader/{{data._id}}" >
                        {{data.name}}</a>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</header>
