
<div mat-dialog-content>
  <h2>Report</h2>
  <mat-form-field appearance="fill">
    <mat-label >Type Report</mat-label>
    <textarea matInput [(ngModel)]="data"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button class="btn btn-primary px-5 rounded-pill" mat-button cdkFocusInitial (click)="reportorder()">Ok</button>
</div>
