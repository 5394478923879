<footer class="footer-element py-equal">
    <div class="container">
        <div class="row">
            <div class="col-4">
                <div class="footer-block mt-4">
                    <a href="#" class="brand d-flex flex-column-reverse justify-content-between mb-3">
                        <figure class="fototer-logo">
                        <img src="../../../assets/images/updated-logo.png" class=" logo">
                        <!-- <img src="assets/images/logo-contant.svg" alt="" class="logo-icn"> -->
                    </figure>
                    </a>
                    <!-- <h3 class="font-20 ff-playfair my-3 text-uppercase text-white">About Us</h3> -->
                    <!-- <P class="text-secondary font-14 text-white">If you are going to use of Lorem
                        Ipsum need to be sure there isn't
                        hidden of text.</P> -->
                </div>
            </div>
            <div class="col-2">
                <div class="footer-blocK">
                    <h3 class="font-20 ff-playfair my-3 text-uppercase text-white">useful Links</h3>
                    <ul>
                        <li class="mb-2"> <img src="assets/images/term.svg"
                            class="mr-3 img-width" alt=""> <a href="https://appgrowthcompany.com/akunamatata/admin/#/cms" class="text-secondary font-14 text-white">Terms and Conditions</a></li>
                        <!-- <li class="mb-2"> <a href="javascript:void(0)" class="text-secondary font-14 text-white">Sell Uniform</a></li> -->
                        <li class="mb-2"> <img src="assets/images/about.svg"
                            class="mr-3 img-width" alt=""><a href="https://appgrowthcompany.com/akunamatata/admin/#/cmsprivacy" class="text-secondary font-14 text-white">Privacy Policy</a></li>
                        <!-- <li class="mb-2"> <a href="https://appgrowthcompany.com/akunamatata/admin/#/cmscontact" class="text-secondary font-14 text-white">Contact Us</a></li> -->
                        <li class="mb-2"><img src="assets/images/faq.svg"
                            class="mr-3 img-width" alt=""> <a href="https://appgrowthcompany.com/akunamatata/admin/#/cmsfaq" class="text-secondary font-14 text-white">FAQs</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-2">
                <div class="footer-blocK">
                    <h3 class="font-20 ff-playfair my-3 text-uppercase text-white">My Account</h3>
                    <ul>
                        <li class="mb-2"> <a href="javascript:void(0)" class="text-secondary font-14 text-white" routerLink="/pages/profile">My Account</a>
                        </li>
                        <li class="mb-2"> <a href="javascript:void(0)" class="text-secondary font-14 text-white" routerLink="/pages/myorder">My Orders</a>
                        </li>
                        <!-- <li class="mb-2"> <a href="javascript:void(0)" class="text-secondary font-14 text-white" routerLink="/pages/changepassword">Change Password</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-4">
                <div class="footer-blocK">
                    <h3 class="font-20 ff-playfair my-3 text-uppercase text-white">Contact Info</h3>
                    <ul>
                        <li class="mb-2">
                            <span class="rounded-circle bg-primary text-white social-circle m-r-10">
                                <i class="mdi mdi-map-marker font-14"></i>
                            </span>
                            <a href="javascript:void(0)" class="text-secondary font-14 text-white">
                                {{cms?.contactAddress}}
                            </a>

                        </li>
                        <li class="mb-2">
                            <span class="rounded-circle bg-primary text-white social-circle m-r-10"> <i
                                    class="mdi mdi-email  font-14"></i>
                            </span>
                            <a href="javascript:void(0)" class="text-secondary font-14 text-white">{{cms?.contactEmail}}
                            </a>
                        </li>
                        <li class="mb-2">
                            <span class="rounded-circle bg-primary text-white social-circle m-r-10"><i
                                    class="mdi mdi-cellphone  font-14"></i></span>
                                    <span *ngIf="cms?.contactPhone">
                                        <a href="javascript:void(0)" class="text-secondary font-14 text-white">
                                            {{cms?.contactCountryCode+' '+cms?.contactPhone}}
                                        </a>

                                    </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>