import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, ReplaySubject } from 'rxjs';
import { catchError, finalize, retry, tap } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private _pendingRequests = 0;
  private _pendingRequestsStatus$ = new ReplaySubject<boolean>(1);
  private _filteredUrlPatterns: RegExp[] = [];
  private _filteredMethods: string[] = [];
  private _filteredHeaders: string[] = [];
  private _forceByPass = false;
  private AUTH_HEADER = 'Authorization';
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private commonService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
  ) {
    if (
      localStorage.accessToken != undefined ||
      localStorage.accessToken != null
    ) {
      this.token = localStorage.accessToken;
    }
  }

get pendingRequestsStatus$(): Observable<boolean> {
    return this._pendingRequestsStatus$.asObservable();
  }
  get pendingRequests(): number {
    return this._pendingRequests;
  }
  get filteredUrlPatterns(): RegExp[] {
    return this._filteredUrlPatterns;
  }
  set filteredMethods(httpMethods: string[]) {
    this._filteredMethods = httpMethods;
  }
  set filteredHeaders(value: string[]) {
    this._filteredHeaders = value;
  }
  set forceByPass(value: boolean) {
    this._forceByPass = value;
  }
  private shouldBypassUrl(url: string): boolean {
    return this._filteredUrlPatterns.some(e => {
      return e.test(url);
    });
  }
  private shouldBypassMethod(req: HttpRequest<any>): boolean {
    return this._filteredMethods.some(e => {
      return e.toUpperCase() === req.method.toUpperCase();
    });
  }
  private shouldBypassHeader(req: HttpRequest<any>): boolean {
    return this._filteredHeaders.some(e => {
      return req.headers.has(e);
    });
  }
  private shouldBypass(req: HttpRequest<any>): boolean {
    return this._forceByPass
      || this.shouldBypassUrl(req.urlWithParams)
      || this.shouldBypassMethod(req)
      || this.shouldBypassHeader(req);
  }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const shouldBypass = this.shouldBypass(req);
    if (!shouldBypass) {
      this._pendingRequests++;
      // if (1 === this._pendingRequests) {
      if (this._pendingRequests > 0) {
        this.commonService.isLoadingComplete.next(false)
        this._pendingRequestsStatus$.next(true);
        this.ngxService.start();
      }
    }
    return next.handle(req).pipe(
      // retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          this.router.navigateByUrl('/pages/home');
        }
        if (error.status == 401) {
          localStorage.clear();
          this.commonService.presentsToast('error', 'top-end', 'you are logout for security purpose.');
          this.router.navigateByUrl('/pages/home');
          this.commonService.setProfile();
          return;
        }
        if (error.status == 0) {
          this.commonService.presentsToast('error', 'top-end', 'Api Not Working.');
        }
        return throwError(error);
      }),
      finalize(() => {
        if (!shouldBypass) {
          this._pendingRequests--;
          // if (0 === this._pendingRequests) {
          if (this._pendingRequests == 0) {
            this._pendingRequestsStatus$.next(false);
            this.ngxService.stop();
            this.commonService.isLoadingComplete.next(true)
          }
        }
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes == undefined) {
            localStorage.clear();
            this.router.navigateByUrl('/pages/home');
            return;
          } else {
            if (eventRes.body) {
              if (eventRes.body.statusCode === 400) {
                if (eventRes.body.error) {
                  this.commonService.presentsToast('error', 'top-end', eventRes.body.error);
                } else if (eventRes.body.message) {
                  this.commonService.presentsToast('error', 'top-end', eventRes.body.message);
                }
              }
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.commonService.presentsToast(
                'warning',
                'top-end',
                'You have been loggedOut for security purpose.'
              );
              localStorage.clear();
              this.router.navigateByUrl('/pages/home');
              break;
            case 500:
              /*** If api does not respond  ** */
              this.commonService.presentsToast(
                'warning',
                'top-end',
                'Api Not Working'
              );
              break;
            case 405:
              /*** If api does not respond  ** */
              this.commonService.presentsToast(
                'warning',
                'top-end',
                'Api Not Working'
              );
              break;
            case 0:
              this.commonService.presentsToast('error', 'top-end', 'Api Not Working.');
              break;
            default:
              if (error.error) {
                this.commonService.presentsToast('error', 'top-end', error.error.message);
              } else {
                this.commonService.presentsToast('error', 'top-end', error.message);
              }
          }
        }
      )
    );
  }
}
