import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChangePasswordComponent } from 'src/app/pages/change-password/change-password.component';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { ChangePassComponent } from '../change-pass/change-pass.component';
import { SetupProfileComponent } from '../setup-profile/setup-profile.component';
import { WelcomeScreenComponent } from '../welcome-screen/welcome-screen.component';

@Component({
  selector: 'app-phone-otp',
  templateUrl: './phone-otp.component.html',
  styleUrls: ['./phone-otp.component.scss']
})
export class PhoneOtpComponent implements OnInit {
  userDetails: any;
  submitted: boolean;
  otpCode: any;
  isLoggedIn: any;
  otp: boolean = false;
  constructor(public modalRef: BsModalRef, private modalService: BsModalService, private router: Router,
    private https: HttpsService,
    private fb: FormBuilder, private common: CommonService) {
    if (localStorage.getItem('akunamatata_web_user')) {
      this.userDetails = JSON.parse(localStorage.getItem('akunamatata_web_user'));
    }
    if (localStorage.getItem('otp')) {
      this.otp = true
      this.userDetails = JSON.parse(localStorage.getItem('otp'));
    }
  }

  ngOnInit() {

  }

  openWelcomeScreen() {
    this.modalRef.hide();
    this.modalService.show(WelcomeScreenComponent, { class: 'modal-lg modal-dialog-centered auth-element' });
  }

  onOtpChange(value) {
    this.otpCode = value;
  }

  setupProfile() {
    this.modalRef.hide();
    this.modalService.show(SetupProfileComponent, { class: 'modal-lg modal-dialog-centered auth-element' });
  }

  changePassword() {
    this.modalRef.hide();
    this.modalService.show(ChangePassComponent, { class: 'modal-lg modal-dialog-centered auth-element' });
  }

  sendPhoneOtp() {
    const reqBody = {
      "phone": this.userDetails?.user.phone,
      "countryCode": this.userDetails?.user.countryCode,
      "type": "VERIFY"
    }
    this.https.httpPost('resendOtp', reqBody).subscribe((res: any) => {
    })
  }

  sendEmailOtp() {
    const reqBody = {
      "email": this.userDetails?.user.email,
      "type": "VERIFY"
    }
    this.https.httpPost('resendOtp', reqBody).subscribe((res: any) => {
    })
  }

  onSubmit() {
    if (this.otpCode) {
      if (this.userDetails) {
        if (this.otp) {
          if (this.userDetails?.isForget) {
            let body = {
              otp: this.otpCode,
              email: this.userDetails['user']['email'],
              type: "FORGOT",
              deviceType: "WEB"
            }
            this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
              if (res['status'] == 200) {
                this.common.presentsToast('success', 'top-end', res['message']);
                localStorage.setItem('accessToken', res['data']);
                this.changePassword();

              } else {
                this.common.presentsToast('error', 'top-end', res['message'])
              }
            })
          } else if (this.userDetails?.isPhoneForget) {
            let body = {
              otp: this.otpCode,
              phone: this.userDetails['user']['phone'],
              countryCode: this.userDetails['user']['countryCode'],
              type: "FORGOT",
              deviceType: "WEB"
            }

            this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
              if (res['status'] == 200) {
                this.common.presentsToast('success', 'top-end', res['message']);
                localStorage.setItem('accessToken', res['data']);
                this.changePassword();

              } else {
                this.common.presentsToast('error', 'top-end', res['message'])
              }
            })
          }
        } else if (this.userDetails?.isLogin) {
          if (this.userDetails.user.email) {
            const reqBody = {
              "email": this.userDetails?.user.email,
              "type": "VERIFY"
            }
            this.https.httpPost('resendOtp', reqBody).subscribe((res: any) => {
              if(res.status ===200){
                let body = {
                  otp: this.otpCode,
                  email: this.userDetails['user']['email'],
                  type: "VERIFY",
                  deviceType: "WEB"
                }
    
                this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
                  if (res['status'] == 200) {
                    this.isLoggedIn = true
                    this.common.presentsToast('success', 'top-end', res['message'])
                    if(localStorage.getItem('accessToken')){
                      this.router.navigateByUrl("/pages/home");
                      this.modalRef.hide();

                    }else{
                      localStorage.clear();
                      this.modalRef.hide();

                    }
    
                  } else {
                    this.common.presentsToast('error', 'top-end', res['message'])
                  }
                })

              }
            })
          }
        } else if (this.userDetails?.isPhoneLogin) {
          const reqBody = {
            "phone": this.userDetails?.user.phone,
            "countryCode": this.userDetails?.user.countryCode,
            "type": "VERIFY"
          }
          this.https.httpPost('resendOtp', reqBody).subscribe((res: any) => {
            if(res.status ===200){
              let body = {
                otp: this.otpCode,
                phone: this.userDetails['user']['phone'],
                countryCode: this.userDetails['user']['countryCode'],
                type: "VERIFY",
                deviceType: "WEB"
              }
    
              this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
                if (res['status'] == 200) {
                  this.common.presentsToast('success', 'top-end', res['message'])
                  if(localStorage.getItem('accessToken')){
                    this.router.navigateByUrl("/pages/home");
                    this.modalRef.hide();

                  }else{
                    localStorage.clear();
                    this.modalRef.hide();

                  }
    
                } else {
                  this.common.presentsToast('error', 'top-end', res['message'])
                }
              })
            }
          })
        }
        else if(this.userDetails?.isSignUp) {
            let body = {
              otp: this.otpCode,
              email: this.userDetails['user']['email'],
              type: "SIGNUP",
              deviceType: "WEB"
            }

            this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
              if (res['status'] == 200) {
                this.isLoggedIn = true
                this.common.presentsToast('success', 'top-end', res['message'])
                this.setupProfile();

              } else {
                this.common.presentsToast('error', 'top-end', res['message'])
              }
            })

        }else if (this.userDetails?.isPhoneSignUp) {
          let body = {
            otp: this.otpCode,
            phone: this.userDetails['user']['phone'],
            countryCode: this.userDetails['user']['countryCode'],
            type: "SIGNUP",
            deviceType: "WEB"
          }

          this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
            if (res['status'] == 200) {
              this.common.presentsToast('success', 'top-end', res['message'])
              this.setupProfile();

            } else {
              this.common.presentsToast('error', 'top-end', res['message'])
            }
          })
        }
      }
    } else {
      this.common.presentsToast('error', 'top-end', "Please enter OTP to proceed");
    }
  }

  resendOtp() {
      if (this.userDetails) {
        if (this.otp) {
          if (this.userDetails?.isForget) {
            let body = {
              email: this.userDetails['user']['email'],
              type: "FORGOT",
            }
            this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
              if (res['status'] == 200) {
                this.common.presentsToast('success', 'top-end', res['message']);
                localStorage.setItem('accessToken', res['data']);

              } else {
                this.common.presentsToast('error', 'top-end', res['message'])
              }
            })
          } else if (this.userDetails?.isPhoneForget) {
            let body = {
              phone: this.userDetails['user']['phone'],
              countryCode: this.userDetails['user']['countryCode'],
              type: "FORGOT",
            }

            this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
              if (res['status'] == 200) {
                this.common.presentsToast('success', 'top-end', res['message']);
                localStorage.setItem('accessToken', res['data']);

              } else {
                this.common.presentsToast('error', 'top-end', res['message'])
              }
            })
          }
        } else if (this.userDetails?.isLogin) {
                let body = {
                  email: this.userDetails['user']['email'],
                  type: "VERIFY",
                }
    
                this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
                  if (res['status'] == 200) {
                    this.common.presentsToast('success', 'top-end', res['message']);
                    localStorage.setItem('accessToken', res['data']);
    
                  } else {
                    this.common.presentsToast('error', 'top-end', res['message'])
                  }
                })

        } else if (this.userDetails?.isPhoneLogin) {
         
              let body = {
                phone: this.userDetails['user']['phone'],
                countryCode: this.userDetails['user']['countryCode'],
                type: "VERIFY",
              }
    
              this.https.httpPost('verifyOtp', body).subscribe((res: any) => {
                if (res['status'] == 200) {
                  this.common.presentsToast('success', 'top-end', res['message']);
                  localStorage.setItem('accessToken', res['data']);
    
                } else {
                  this.common.presentsToast('error', 'top-end', res['message'])
                }
              })
        }
        else if(this.userDetails?.isSignUp) {
            let body = {
              email: this.userDetails['user']['email'],
              type: "SIGNUP",
            }

            this.https.httpPost('resendOtp', body).subscribe((res: any) => {
              if (res['status'] == 200) {
                this.isLoggedIn = true
                this.common.presentsToast('success', 'top-end', res['message']);
              //localStorage.setItem('accessToken', res['data']);

              } else {
                this.common.presentsToast('error', 'top-end', res['message'])
              }
            })

        }else if (this.userDetails?.isPhoneSignUp) {
          let body = {
            phone: this.userDetails['user']['phone'],
            countryCode: this.userDetails['user']['countryCode'],
            type: "SIGNUP",
          }

          this.https.httpPost('resendOtp', body).subscribe((res: any) => {
            if (res['status'] == 200) {
              this.common.presentsToast('success', 'top-end', res['message']);
              localStorage.setItem('accessToken', res['data']);

            } else {
              this.common.presentsToast('error', 'top-end', res['message'])
            }
          })
        }
      }
  }


}
