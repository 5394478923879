import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { WelcomeScreenComponent } from '../welcome-screen/welcome-screen.component';
import { Router } from '@angular/router';
import { HttpsService } from 'src/app/services/http/http.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-setup-profile',
  templateUrl: './setup-profile.component.html',
  styleUrls: ['./setup-profile.component.scss']
})
export class SetupProfileComponent implements OnInit {

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Kuwait];
  submitted: boolean;
  profileForm: FormGroup;
  isLoggedIn: boolean;
  userDetails: any;
  user: any;
  toppings = new FormControl();
  countryList = ['India', 'Australia', 'Canada', 'US', 'Japan', 'Germany'];
  stateList = ['Haryan', 'New South Wales', 'Torronto',];
  bankName= ['HDFC', 'HSBC', 'ICICI',];
  accountHolder= ['John', 'Andrew', 'Sandy',];
  fileData: any;
  imageSrc: any;
  pickup = { lat: 0, lng: 0, address: '' };
  geoCoder: any;

  zoom: number = 12;
  address: string;
  isPhone: boolean=false;
  isEmail: boolean=false;

  constructor(public modalRef: BsModalRef, private modalService: BsModalService,private router: Router,
    private https: HttpsService,
    private fb: FormBuilder,private common: CommonService) { 
      if(localStorage.getItem("akunamatata_web_user")){
        this.userDetails= JSON.parse(localStorage.getItem('akunamatata_web_user'))
      }
    }

  ngOnInit(): void {

    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['',Validators.required],
      countryCode:[''],
      profilePic:[''],
      gender:['',Validators.required],
      phone:['',Validators.required],
      homeAddress: ['', Validators.required],
      latitude: [""],
      longitude: [""],
    });

    if(this.userDetails.user.email){
      this.isEmail=true
      this.profileForm.patchValue({
        firstName:this.userDetails.user.firstName,
        lastName:this.userDetails.user.lastName,
        email:this.userDetails.user.email,
      })
    }

    if(this.userDetails.user.phone){
      this.isPhone=true
      this.profileForm.patchValue({
        firstName:this.userDetails.user.firstName,
        lastName:this.userDetails.user.lastName,
        phone: {
          number: this.userDetails.user.countryCode + ' ' + this.userDetails.user.phone,
        },
        countryCode: this.userDetails.countryCode,

      })
    }
  }


openWelcomeScreen(){
    this.modalRef.hide();
    this.modalService.show(WelcomeScreenComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }


  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.fileData = event.target.files[0];
      this.https.fileUpload(this.fileData).subscribe((res: any) => {
        this.imageSrc = res.data;
        this.profileForm.patchValue({profilePic:this.imageSrc})
      });
    }
  }
  onKeyUpp(value) {

    if (value === "") {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.profileForm.get('homeAddress').patchValue(null);

    }
  }
  pickupChange(value) {

    if (this.pickup.lat === 0 && this.pickup.lng === 0) {
      this.profileForm.get('homeAddress').patchValue(null);

    } else if (this.address !== value) {
      this.profileForm.get('homeAddress').patchValue(null);

    }
  }
  handlePickUpChnage(address) {

    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickup.lat = (address.geometry.location.lat())
      this.pickup.lng = (address.geometry.location.lng())
      this.address = (address.formatted_address);
      this.profileForm.get('homeAddress').patchValue(this.address);
      this.profileForm.patchValue({
        latitude:this.pickup.lat,
        longitude:this.pickup.lng
      })

    }
  }
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.pickup.lat = position.coords.latitude;
        this.pickup.lng = position.coords.longitude;
        this.zoom = 15;
        this.getAddressDrop(this.pickup, '');
      });
    }
  }
  getAddressDrop(latLng, formatted_address) {

    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            this.profileForm.controls['homeAddress'].patchValue(addresses.formatted_address);

            return false;
          }
        });
        if (!isAddSimilar) {
          this.profileForm.controls['homeAddress'].patchValue(results[0].formatted_address);

        }
      } else {
      }
    });
  }

  btn_Continue(){
    this.submitted=true;
    if(this.profileForm.valid){
      var codeLength = this.profileForm.value.phone.dialCode.length;
      this.profileForm.patchValue({
        countryCode:this.profileForm.value.phone.dialCode,
        phone:this.profileForm.value.phone.e164Number.slice(codeLength),
      })
      
      let data = this.profileForm.value;
      data.address={
        "homeAddress":this.profileForm.value.homeAddress,
         addType:"PARMANENT"
      }
      delete data.homeAddress
      this.https.httpPostWithHeader('profile', data,false).subscribe((res: any) => {
        if(res['status']==200){
          if(res['body']['data']){
            let data={
              user:res['body']['data']
            }
            localStorage.setItem('akunamatata_web_user',JSON.stringify(data));
            this.common.setProfile();
            this.common.presentsToast('success', 'top-end',res['body']['message'])
            this.openWelcomeScreen();

          }
        }
      })
    }else{
      this.profileForm.markAllAsTouched();
    }
  }
}
