import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  value: number = 0;
  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 200,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '$' + value;
        case LabelType.High:
          return '$' + value;
        default:
          return '$' + value;
      }
    }

  };

  @Input() public transferAmount: any;
  @Input() transferAmount1:any = 0;
  @Output() inChange: EventEmitter<any> = new EventEmitter();
  categoryData: any;
  subCategoryData: any;
  categoryId: any;
  categoryIds: any;
  subCategoryIds: any[] =[];
  rating: any;
  sortBy: any;
  from: any;
  to: any;
  subscription: any;
  searchCategory: string
  searchSubCategory: string;
  categroy: any
  subCategroy: any
  star: any
  type: any
  constructor(private router: Router,
    private https: HttpsService,
    private fb: FormBuilder, private common: CommonService,private activatedRoute: ActivatedRoute) {
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
  
          this.categoryIds = this.activatedRoute.snapshot.paramMap.get("id");
          this.categroy = this.categoryIds
          if(this.categoryIds)
          this.subCategory(this.categoryIds);
        }
      })


  }

  ngOnInit(): void {
    this.categoryIds = this.activatedRoute.snapshot.paramMap.get("id");
    this.categroy = this.categoryIds
    if(this.categoryIds)
    this.subCategory(this.categoryIds);
    if (this.transferAmount) {
      this.options.ceil = Math.ceil(this.transferAmount)
      this.highValue = Math.ceil(this.transferAmount);
    }
    if (this.transferAmount1) {
      this.options.floor = Math.floor(this.transferAmount1)
      this.value = Math.floor(this.transferAmount1);
    }
    this.category();


  }

  category() {
    if (this.searchCategory) {
      this.https.httpGetWithHeaderParams('category', 'search=' + this.searchCategory).subscribe((res: any) => {
        if (res['status'] == 200) {
          this.categoryData = res['body']['data']['categories']
          console.log(this.categoryData);
          
        }
      })
    } else {
      this.https.httpGetWithHeader('category').subscribe((res: any) => {
        if (res['status'] == 200) {
          this.categoryData = res['body']['data']['categories']
        }
      })
    }

  }

  onCategoryChange(event, id, data) {
    this.categoryIds = id
    // this.router.navigate(['pages/categoryheader',this.categoryIds])
   
    let curr = {
      categoryId: this.categoryIds,
      sortby: this.sortBy,
      rating: this.rating,
      from: this.value,
      to: this.highValue
    }
    this.inChange.emit(curr);
    this.subCategory(this.categoryIds);
  }

  onSubCategoryChange(event, id, i) {
    console.log(id,"id");
    
    this.subCategoryData[i].checked = event.checked
    console.log(this.subCategoryData,"this.subCategoryData[");
    
    console.log(event.checked);
    if(event.checked){
      this.subCategoryIds.push(id)
    }else{
      let findIndex = this.subCategoryIds.findIndex(x=>x == id)
      if(findIndex != -1)
      {
        this.subCategoryIds.splice(findIndex,1)
      }
    }
    console.log(this.subCategoryIds,"this.subCategoryIds");
    
    let curr:any;
    if(this.subCategoryIds.length ==0){
      curr = {
        categoryId: this.categoryIds,
        sortby: this.sortBy,
        rating: this.rating,
        from: this.value,
        to: this.highValue
      }
      this.inChange.emit(curr);
    }
    else{
      curr = {
        subCategoryId: this.subCategoryIds,
        sortby: this.sortBy,
        rating: this.rating,
        from: this.value,
        to: this.highValue
      }
      this.inChange.emit(curr);
    }
  
    
  }



  onRatingChange(event) {
    this.rating = event.value
    let curr = {
      categoryId: this.categoryIds,
      subCategoryId: this.subCategoryIds,
      sortby: this.sortBy,
      rating: Number(event.value),
      from: this.value,
      to: this.highValue
    }
    this.inChange.emit(curr);
  }

  onSortChange(event) {
    console.log(event);
    
    this.sortBy = event.value
    
    let curr = {
      categoryId: this.categoryIds,
      subCategoryId: this.subCategoryIds,
      sortby: event.value,
      rating: this.rating,
      from: this.value,
      to: this.highValue
      
    }
    this.inChange.emit(curr);
  }
  sliderEvent() {
    let curr = {
      categoryId: this.categoryIds,
      subCategoryId: this.subCategoryIds,
      sortby: this.sortBy,
      rating: this.rating,
      from: this.value,
      to: this.highValue
    }
    this.inChange.emit(curr);
  }
  subCategory(id) {
    if (this.searchSubCategory) {
      this.https.httpGetWithHeaderParams('subcategory', 'id=' + this.categoryIds + '&search=' + this.searchSubCategory).subscribe((res: any) => {
        (res['status'] == 200)
        {
          this.categoryData = res['body']['data']['categories']
        }
      })
    } else {
      this.https.httpGetWithHeaderParams('subcategory', 'id=' + this.categoryIds).subscribe((res: any) => {
        if (res['status'] == 200) {
          this.subCategoryData = res['body']['data']['subcategories']
          for(var i=0;i<this.subCategoryData.length;i++){
            this.subCategoryData[i].checked =false
          }
          console.log(this.subCategoryData,"subcategorys");
          

        }
      })

    }
  }

  resetFilter() {
    window.location.reload()
    this.value = 0
    this.categroy = null
    this.subCategroy = null
    this.star = null
    this.searchSubCategory=""
    this.type = null
    this.highValue = this.transferAmount
    let curr = {}
    this.inChange.emit(curr);
    var checkbox = document.getElementById("subcategorycheck") as HTMLInputElement
    checkbox.checked =false

    
  }

}
