import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard/auth.service';

const routes: Routes = [
  {
    path:'',
    redirectTo:'/pages/home',
    pathMatch:'full'
  },
  {
    path:'auth',
    loadChildren:() => import('./authentiction/authentiction.module').then(m => m.AuthentictionModule),
  },
  {
    path:'layout',
    loadChildren:() => import('./layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path:'pages',
    loadChildren:() => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard],

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
