<div class="detail-element">
    <div class="siderbar-block">
        <div class=" align-items-center d-flex justify-content-between mb-3 reviwe">
            <div class="reviwe_detail_lft">
                <h2 class=" font-bold">Order ID : #{{orderDetails?.orderNo}}</h2>
                <h4 class="text-secondary">{{orderDetails?.createdAt | date:'MMM d, y, h:mm a'}}</h4>
            </div>
            <div class="reviwe_detail_ryt text-right">
                <label class="badge btn-primary text-white p-2 font-16 px-xl-3 px-lg-2">
                    {{orderDetails?.status}}</label>
            </div>
        </div>
        <div class="row mb-3" *ngFor="let data of cartData?.products">
            <div class="col-md-4" routerLink="/pages/productdetail/{{data?.productId}}/">
                <div class="reviwe_detail_left_inner">
                    <figure>
                        <img [src]="data?.images[0] ? data?.images[0] : 'assets/images/printShirt.png'" alt="dress">
                    </figure>
                </div>
            </div>
            <div class="col-md-8">
                <div class="reviwe-content">
                    <h3 class="font-22 font-medium ff-playfair" routerLink="/pages/productdetail/{{data?.productId}}/">{{data?.name | titlecase}}</h3>
                    <h4 class="text-secondary font-light ff-playfair m-0 font-20">{{data?.brand}}</h4>
                    <p class="text-black m-0">{{data?.qty}} x $ {{data?.productPrice | number:'1.2-2'}} = $ {{data?.total | number:'1.2-2'}}</p>
                    <h2 class=" font-medium ff-playfair font-16 m-0"><span style="
                        position: relative;
                        top: 26px;
                    ">Color:</span><span class="text-secondary font-16 mr-4">
                            <div class="custom-radios">
                                <div>
                                    <input type="radio" id="color-1" name="color">
                                    <label for="color-1">
                                        <span [style.background-color]="data.selectedColor"
                                            style="position:relative;right:-105%;">
                                            <!-- <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"  alt="Checked Icon" /> -->
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </span> Size: <span class="text-secondary font-16">{{data?.selectedSize}}</span> </h2>
                    <div class="btn-banner d-flex mt-1" *ngIf="orderDetails1?.orderstatus?.status=='COMPLETED'">
                          
                        <div class="lft" *ngIf="orderDetails1?.cart[0]?.products[0]?.isReturn == false"> <button class="b-0 mr-2 px-4 py-2 rounded-pill text-white viwe-btn"
                                (click)="myarr()">Return</button></div>
                        <div class="ryt"><button class="btn text-white btn-primary rounded-pill px-4 "
                                routerLink="/pages/ReviweProduct/{{data?.productId}}">Rate Now</button></div>
                    </div>
                </div>
            </div>
        </div>                                                                          
        <div class="price_detail_right_inner cart mt-3">
            <div class="title_bar cart">
                <h4 class="ff-sanspro font-22 font-bold">Price Details</h4>
            </div>
            <ul class="product_detail_tab_content">
                <li class="align-content-center d-flex justify-content-between mt-2">
                    <p class="font-16 text-secondary font-light m-0">Sub Total :</p>
                    <p class=" font-16 font-light m-0">$ {{cartData?.productsPrice ? cartData?.productsPrice : '0.00'}}</p>
                </li>
                <li class="align-content-center d-flex justify-content-between mt-2">
                    <p class=" font-16 text-secondary font-light m-0"> Discount Apply :</p>
                    <p class=" font-16 font-light ">$ {{cartData?.taxAndCharge ? cartData?.taxAndCharge: '0.00'}}</p>
                </li>
                <li class="align-content-center d-flex justify-content-between mt-2">
                    <p class=" font-16 text-secondary font-light m-0"> Delivery Charge :</p>
                    <p class=" font-16 font-light ">$ {{cartData?.taxAndCharge ? cartData?.taxAndCharge: '0.00'}}</p>
                </li>
            </ul>
            <ul class="product_detail_tab_content total mt-2">
                <li class="align-content-center d-flex justify-content-between">
                    <p class="font-16 font-light m-0">Total Charge</p>
                    <p class=" font-16 font-bold ">$ {{cartData?.totalPrice ? cartData?.totalPrice : '0.00'}}</p>
                </li>
            </ul>
            <div *ngIf="orderDetails?.status=='COMPLETED'">
            <button class="btn btn-primary px-3 text-white w-100 rounded-pill p-t-10 p-b-10" (click)="repeatorder(orderDetails.cartId)">Repeat Order</button>
            </div>  
            <br>
            <div *ngIf="orderDetails?.status=='COMPLETED'" class="retun_btn">
                <button class="btn" mat-raised-button (click)="openDialog(orderDetails._id)">Report Order</button>
                </div>  
        </div>
    </div>
</div>