import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { LoginComponent } from 'src/app/authentiction/login/login.component';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { threadId } from 'worker_threads';
import { SocketioService } from 'src/app/services/sockets/socketio.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('pendingSearch') pendingSearchref: ElementRef;

  headerSearch: any = ""

  modalRef?: BsModalRef;
  userDetails: any;
  address: any;
  search: any = '';
  private subject: Subject<string> = new Subject();
  clearSearch: boolean;
  selectedKey: boolean = false;
  searchSuggestionList: any = []
  cartCount: any;
  currentUrl: any;
  user: any;
  currentLang: any;
  lat: number;
  lng: number;
  pickup = { lat: 0, lng: 0, address: '' };
  filters: any[] = [];
  private geoCoder;
  @ViewChild('search') searchElementRef: ElementRef;
  selectedType: any = 'All';
  zoom: number = 12;
  location: FormGroup
  data: any;
  searchCategory: string = '';
  categoryData: any;
  showPendingCrossIcon: boolean;
  newBookingnotificationObservable: any;
  returnBookingnotificationObservable: any;
  bookingStatusnotificationObservable: any;
  cat: any = [];
  constructor(private modalService: BsModalService, private router: Router, private https: HttpsService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,
    private activateRoute: ActivatedRoute, private common: CommonService, private fb: FormBuilder, public socket: SocketioService) {


    this.socket.initSocket()
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.data = JSON.parse(localStorage.getItem('akunamatata_web_user'))
        if (localStorage.getItem('akunamatata_web_user'))
          this.getCartCount()
        console.log(this.router.url, "this.activateRoute.url");
        if (!this.router.url.includes("search")) {
          this.headerSearch = ""
        }
      }
    })
    this.common.getProfile().subscribe(res => {
      this.data = res;
    })
    if (this.newBookingnotificationObservable == undefined)
      this.newBookingnotificationObservable = this.socket
        .newBookingSocket()
        .subscribe(
          (res) => {
            console.log("notification here");
            if (res) {
              console.log(res, "listener rrepsoe");
              this.common.presentsToast('success', 'top-end', res.message);
              // this.getData();
              // this.commonService.hideSpinner();
              // this.playAudio();
            }
          },
          (error: any) => {
            console.log("error", error);
          }
        );
    if (this.returnBookingnotificationObservable == undefined)
      this.returnBookingnotificationObservable = this.socket
        .returnBookingSocket()
        .subscribe(
          (res) => {
            console.log("notification here");
            if (res) {
              console.log(res, "listener rrepsoe");
              this.common.presentsToast('success', 'top-end', res.message);
              // this.getData();
              // this.commonService.hideSpinner();
              // this.playAudio();
            }
          },
          (error: any) => {
            console.log("error", error);
          }
        );
    if (this.bookingStatusnotificationObservable == undefined)
      this.bookingStatusnotificationObservable = this.socket
        .bookingStatusSocket()
        .subscribe(
          (res) => {
            console.log("notification here");
            if (res) {
              console.log(res, "listener rrepsoe");
              this.common.presentsToast('success', 'top-end', res.message);
              // this.getData();
              // this.commonService.hideSpinner();
              // this.playAudio();
            }
          },
          (error: any) => {
            console.log("error", error);
          }
        );
  }

  ngOnInit(): void {
    this.getCurrentLocation();
    this.geoCoder = new google.maps.Geocoder;
    this.categoryy();
    // this.currentUrl = this.router.url;
    // this.subject.pipe(
    //   debounceTime(500)
    // ).subscribe(searchTextValue => {
    //   if (searchTextValue.length >= 1) {
    //     this.onSearchSuggestion(searchTextValue);
    //   } else if (searchTextValue.length < 1) {
    //     this.searchSuggestionList = [];
    //   }
    // });
    this.common.getCart().subscribe(res => {
      // this.cartCount = res;
      console.log(res, "getvcart");

    });



    this.location = this.fb.group({
      address: [""],
      latitude: [""],
      longitude: [""],

    });
    this.common.getProfile().subscribe(res => {
      this.userDetails = res;
    })

    this.common.getLocation().subscribe(res => {
      if (res) {
        this.address = res['address'];
      }
    })
    if (localStorage.getItem('akunamatata_web_address_location')) {
      let locateData = JSON.parse(localStorage.getItem('akunamatata_web_address_location'));
      this.address = locateData['address'];
    }
  }

  getCartCount() {
    this.https.httpGetWithHeader('cart').subscribe((res: any) => {

      this.cartCount = res['body']['data']?.['cart']?.['products'].length || 0;
      console.log(this.cartCount, "cartcount");



    }, (error: any) => {
      if (error.status == 403) {
        this.router.navigateByUrl('/pages/profile')
        this.common.presentsToast('error', 'top-end', "Please Add Your Permanent Address.")
      }
    })

  }


  ngAfterViewInit() {
    fromEvent(this.pendingSearchref.nativeElement, 'keyup')
      .pipe(
        map((i: any) => i.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((res: any) => {
        if (res) {
          this.showPendingCrossIcon = true;
          this.searchCategory = res;
          console.log('feasgedfdsf');
          this.router.navigate(["/pages/search"],
            {
              queryParams: {
                'search': res
              }
            }
          );
          this.category()
          // this.getPendingTeammateData(this.currentPage, res,true);
        } else {
          this.showPendingCrossIcon = false;
          this.searchCategory = '';
          this.router.navigateByUrl("/pages/home");
          // this.getPendingTeammateData(this.currentPage,'',true);
          this.category()
        }
      });
  }
  openModal() {
    this.modalRef = this.modalService.show(LoginComponent, { class: 'modal-lg modal-dialog-centered auth-element' });
  }



  // onSearchSuggestion(search) {
  //   this.common.getLocation().subscribe(res => {
  //     if (res) {
  //       let data = {
  //         latitude: res['latitude'],
  //         longitude: res['longitude'],
  //         search: search
  //       }
  //       // this.common.searchProduct(data).subscribe(res => {
  //       //   if (res['success']) {
  //       //     this.searchSuggestionList = res['data'];
  //       //     if (this.searchSuggestionList && this.searchSuggestionList.length === 0) {
  //       //       // this.merchantList = [];
  //       //       this.selectedKey = false;
  //       //     }
  //       //   }
  //       // })
  //     }
  //   })
  // }

  categoryy() {

    this.https.httpGetWithHeader('category').subscribe((res: any) => {
      if (res['status'] == 200) {
        this.categoryData = res['body']['data']['categories']
        this.cat = res.body.data.categories
        console.log(this.cat);

      }
    })
  }


  getCurrentLocation() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var data
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;

          this.zoom = 15;

          this.getAddress(position.coords.latitude, position.coords.longitude);

        },
        (err) => {
          console.log(err);
        }
      );
    }

  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(latitude, longitude, 'coord');

      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.location.patchValue({
            address: this.address
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });

  }

  category() {
    if (this.searchCategory) {
      this.common.isSearchChange.next(this.searchCategory)
      //   this.https.httpPost('searchBar', 'search=' + this.searchCategory).subscribe((res: any) => {
      //     if (res['status'] == 200) {
      //       this.categoryData = res['body']['data']['categories']
      //     }
      //   })
    }
  }

  logOut() {
    let webAddress;

    if (localStorage.getItem('akunamatata_web_address_location')) {
      webAddress = localStorage.getItem('akunamatata_web_address_location');
    }

    if (localStorage.getItem('akunamatata_web_user')) {
      localStorage.clear();
      this.common.setProfile();
      this.router.navigateByUrl("/pages/home");
    }
    if (webAddress) {
      localStorage.setItem('akunamatata_web_address_location', webAddress);
      this.common.setLocation();
    }
  }

  onKeyUpp(value) {

    if (value === "") {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.location.get('address').patchValue(null);

    }
  }

  pickupChange(value) {

    if (this.pickup.lat === 0 && this.pickup.lng === 0) {
      this.location.get('address').patchValue(null);

    } else if (this.address !== value) {
      this.location.get('address').patchValue(null);

    }
  }

  handlePickUpChnage(address) {

    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickup.lat = (address.geometry.location.lat())
      this.pickup.lng = (address.geometry.location.lng())
      this.address = (address.formatted_address);
      this.location.get('address').patchValue(this.address);
      this.location.patchValue({
        latitude: this.pickup.lat,
        longitude: this.pickup.lng
      })

    }
  }

  getAddressDrop(latLng, formatted_address) {
    if (latLng) {
      this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
        if (status === 'OK' && results.length > 0) {
          var isAddSimilar = false;
          results.forEach(addresses => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              this.location.controls['address'].patchValue(addresses.formatted_address);

              return false;
            }
          });
          if (!isAddSimilar) {
            this.location.controls['address'].patchValue(results[0].formatted_address);

          }
        } else {
        }
      });

    }
  }



  onSearchSuggestion(search) {
    this.common.getLocation().subscribe(res => {
      if (res) {
        let data = {
          latitude: res['latitude'],
          longitude: res['longitude'],
          search: search
        }
        // this.common.searchProduct(data).subscribe(res => {
        //   if (res['success']) {
        //     this.searchSuggestionList = res['data'];
        //     if (this.searchSuggestionList && this.searchSuggestionList.length === 0) {
        //       // this.merchantList = [];
        //       this.selectedKey = false;
        //     }
        //   }
        // })
      }
    })
  }


}
