import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
//   heartIcons = {
//     empty: '../assets/heart-empty.svg',
//     half: '../assets/heart-half.svg',
//     full: '../assets/heart-full.svg',
// }
  title = 'clothing-website';

  constructor(public updates: SwUpdate){
    updates.available.subscribe((_) =>
			updates.activateUpdate().then(() => {
				document.location.reload();
			})
		);
  }
}
