import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { EmailVerificationComponent } from '../email-verification/email-verification.component';
import { PhoneOtpComponent } from '../phone-otp/phone-otp.component';
import { PhoneSingupComponent } from '../phone-singup/phone-singup.component';
import {MustMatch} from '../../services/must-match.validators'
import { LoginComponent } from '../login/login.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  submitted: boolean;
  signUpForm: FormGroup;
  isLoggedIn: boolean;
  userDetails: any;
  user: any;
  yourRedirectURIVar: any = 'https://dev.webdevelopmentsolution.net/akunamatata/web/pages/home';
  clientId: any = 'dev.webdevelopmentsolution';
  jwtHelper = new JwtHelperService();
  constructor(public modalRef: BsModalRef, private modalService: BsModalService,private router: Router,private socialAuthService: SocialAuthService,
    private https: HttpsService,
    private fb: FormBuilder,private common: CommonService) { }

  ngOnInit(): void {

    this.signUpForm = this.fb.group({
      firstName:['',Validators.required],
      lastName:['',Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: MustMatch('password', 'confirmPassword'),
    });
  }
  openPhoneSignup() {
    this.modalRef.hide();
    this.modalService.show(PhoneSingupComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }
  openEmailVerification() {
    this.modalRef.hide();
    this.modalService.show(EmailVerificationComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }
  openPhoneOtp() {
    this.modalRef.hide();
    this.modalService.show(PhoneOtpComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openLoginEmail(){
    this.modalRef.hide();
    this.modalService.show(LoginComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  btn_signUp(){
    this.submitted = true;
    if(this.signUpForm.valid){
      let data = this.signUpForm.value;
        data['deviceType']='WEB';
        delete data['confirmPassword'];
        this.https.httpPost('signUp', data).subscribe((res: any) => {
          if(res['status']==201){
            if(res['data']){
              res['data']['isSignUp']=true
              localStorage.setItem('akunamatata_web_user',JSON.stringify(res['data']));
              localStorage.setItem('accessToken',res['data']['token']);
              this.openPhoneOtp();
            }
          }
        })
    }else{
      this.signUpForm.markAllAsTouched();
    }
  }

  signInWithFB(): void {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "FACEBOOK",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Apptunix");
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();
          } else {
            this.common.presentsToast('error', 'top-end',res['message'])

          }
        })
      }
    })
  }


  signInWithGoogle() {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "GOOGLE",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();
                this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Apptunix");

            } else {
            this.common.presentsToast('error', 'top-end',res['message'])
          }
        })
      }
    });
  }

  openAppleAuthWindow() {
    window.open(
      'https://appleid.apple.com/auth/authorize?' +
        `client_id=${this.clientId}&` +
        `redirect_uri=${encodeURIComponent(this.yourRedirectURIVar)}&` +
        //`redirect_uri=${this.yourRedirectURIVar}&` +
        'response_type=code id_token&' +
        'state=state&' +
        'nonce=nonce&' +
        //'scope=name email&' +
        'response_mode=fragment',
      '_self'
    );
  }
}
