import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketioService {
  socket: any;
  websiteConnect: Subject<boolean> = new Subject<boolean>();
  isWebsiteConnect = this.websiteConnect.asObservable();

  constructor() {}

  initSocket() {
    if (localStorage.accessToken !== undefined) {
      this.socket = socketIo.connect(environment.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: {
          token: localStorage.accessToken,
        },
      });
      console.log("!111111111111111111",this.socket);
      
    }
   
    // data &&
    //   this.socket.on('connect', (res: any) => {
    //     this.emitAction('connectToChat', data);
    //   });

    // this.socket.on('disconnect', (message: any) => {
    // });
  }
  newBookingSocket() {
    return Observable.create((observer) => {
      this.socket.removeListener("newBooking");
      this.socket.on("newBooking", (message) => {
        console.log("newBooking", message);
        observer.next(message);
      });
    });
  }
  returnBookingSocket() {
    return Observable.create((observer) => {
      this.socket.removeListener("returnBooking");
      this.socket.on("returnBooking", (message) => {
        console.log("returnBooking", message);
        observer.next(message);
      });
    });
  }
  bookingStatusSocket() {
    return Observable.create((observer) => {
      this.socket.removeListener("bookingStatus");
      this.socket.on("bookingStatus", (message) => {
        console.log("bookingStatus", message);
        observer.next(message);
      });
    });
  }

  emitAction(action: any, payload: any): void {
    this.socket.emit(action, payload);
  }

  public onEvent(event: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(event, (data) => observer.next(data));
    });
  }

  close() {
    this.socket.close();
  }
}
