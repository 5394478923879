import { Component, Inject, OnInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Toast, ToastrService } from 'ngx-toastr';
import { DelivredorderSidebarComponent } from 'src/app/pages/delivredorder-sidebar/delivredorder-sidebar.component';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-report-order',
  templateUrl: './report-order.component.html',
  styleUrls: ['./report-order.component.scss']
})
export class ReportOrderComponent implements OnInit {

  data: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data1: DelivredorderSidebarComponent, private https: HttpsService, private toastr: ToastrService,
    private dialogRef: MatDialogRef<any>
  ) {
    console.log(this.dialogRef, 'lll', this.data);
    console.log(this.data1);
  }

  ngOnInit(): void {
  }

  onNoClick() { };

  reportorder() {
    var param = {
      orderId: this.data1,
      comment: this.data
    }
    console.log(param);
    this.https.httpPostWithHeader('reportOrder', param, true).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.toastr.success('Reported Successfully');
        this.dialogRef.close()
      } else {
        this.toastr.error(res.message);
      }
    })
  }
}
