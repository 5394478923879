import { Component, Input, OnInit } from '@angular/core';
import { Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NavigationExtras, RouteConfigLoadEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReportOrderComponent } from 'src/app/modals/report-order/report-order.component';
import { HttpsService } from 'src/app/services/http/http.service';

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-delivredorder-sidebar',
  templateUrl: './delivredorder-sidebar.component.html',
  styleUrls: ['./delivredorder-sidebar.component.scss']
})
export class DelivredorderSidebarComponent implements OnInit {

  @Input() public value: any;
  orderDetails: any;
  orderDetails1: any;
  cartData: any;
  animal: string;
  name: string;

  constructor(public dialog: MatDialog,private https: HttpsService, private toastr: ToastrService,private router: Router,) { }

  ngOnInit(): void {
    this.orderDetails1 = this.value
    this.orderDetails = this.value.orderstatus
    console.log(this.value,'0000000000000000000000000000000000000000000' );
    console.log(this.orderDetails1.cart[0].products[0].isReturn);
    
    this.cartData = this.value.cart[0]
    //  console.log(this.orderDetails?._id);
    

  }
  openDialog(id): void {
    const dialogRef = this.dialog.open(ReportOrderComponent, {
      width: '500px',height:'400px',
      data:id
     
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }
  myarr(){
    // this.router.navigate(["pages/returnorder"], {
    //   queryParams: {
    //     prop: JSON.stringify(this.value)
    //   }
    // });
    const queryParams: any = {};
    const arrayOfValues = this.value;
    queryParams.myArray = JSON.stringify(arrayOfValues);

    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/pages/returnorder'], navigationExtras);
  }

  repeatorder(oid)
  {
    var param = {
      cartId: oid
    }
    console.log(oid);
    this.https.httpPostWithHeader('repeatOrder', param, true).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.toastr.success('Added in Cart successfully');
        this.router.navigateByUrl("/pages/shopingitem");
        // this.dialogRef.close()
      } else {
        this.toastr.error(res.message);
      }
    })
  }
}
